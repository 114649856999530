import { SearchDocumentType, ApiTypes } from '@wix/client-search-sdk';
import { NotTranslatedDropdownOption } from '../SearchResultsControllerStore.types';

const enum SortOptionId {
  Default = 0,
  ForumLastActivityDateDesc = 1,
  ForumCreatedDateDesc = 2,
  ForumTotalCommentsDesc = 3,
  ForumViewCountDesc = 4,
  ForumLikeCountDesc = 5,
  EventsStartDateDesc = 8,
  BlogPublishDateDesc = 9,
}

const enum ForumOrderingFields {
  createdDate = 'createdDate',
  lastActivityDate = 'lastActivityDate',
  likeCount = 'likeCount',
  totalComments = 'totalComments',
  viewCount = 'viewCount',
}

const enum EventsOrderingFields {
  startDate = 'startDate',
}

const enum BlogOrderingFields {
  publishDate = 'publishDate',
}

const enum OrderingDirection {
  ASC = 'ASC',
  DESC = 'DESC',
}

const sortOptionConfig: {
  [key in SortOptionId]: {
    translation: string;
    sorters: ApiTypes.ISearchRequestSorters[];
  };
} = {
  [SortOptionId.Default]: {
    translation: 'searchResults.sort.options.default',
    sorters: [],
  },
  [SortOptionId.ForumLastActivityDateDesc]: {
    translation:
      'searchResults.sort.dropdownOptions.forum.lastActivityDate.desc',
    sorters: [
      {
        fieldName: ForumOrderingFields.lastActivityDate,
        direction: OrderingDirection.DESC,
      },
    ],
  },
  [SortOptionId.ForumCreatedDateDesc]: {
    translation: 'searchResults.sort.dropdownOptions.forum.createdDate.desc',
    sorters: [
      {
        fieldName: ForumOrderingFields.createdDate,
        direction: OrderingDirection.DESC,
      },
    ],
  },
  [SortOptionId.ForumLikeCountDesc]: {
    translation: 'searchResults.sort.dropdownOptions.forum.likeCount.desc',
    sorters: [
      {
        fieldName: ForumOrderingFields.likeCount,
        direction: OrderingDirection.DESC,
      },
    ],
  },
  [SortOptionId.ForumTotalCommentsDesc]: {
    translation: 'searchResults.sort.dropdownOptions.forum.totalComments.desc',
    sorters: [
      {
        fieldName: ForumOrderingFields.totalComments,
        direction: OrderingDirection.DESC,
      },
    ],
  },
  [SortOptionId.ForumViewCountDesc]: {
    translation: 'searchResults.sort.dropdownOptions.forum.viewCount.desc',
    sorters: [
      {
        fieldName: ForumOrderingFields.viewCount,
        direction: OrderingDirection.DESC,
      },
    ],
  },
  [SortOptionId.EventsStartDateDesc]: {
    translation: 'searchResults.sort.dropdownOptions.events1.startDate.desc',
    sorters: [
      {
        fieldName: EventsOrderingFields.startDate,
        direction: OrderingDirection.DESC,
      },
    ],
  },
  [SortOptionId.BlogPublishDateDesc]: {
    translation: 'searchResults.sort.dropdownOptions.blog.publishDate.desc',
    sorters: [
      {
        fieldName: BlogOrderingFields.publishDate,
        direction: OrderingDirection.DESC,
      },
    ],
  },
};

const forumSortOptions = [
  SortOptionId.Default,
  SortOptionId.ForumLastActivityDateDesc,
  SortOptionId.ForumCreatedDateDesc,
  SortOptionId.ForumTotalCommentsDesc,
  SortOptionId.ForumViewCountDesc,
  SortOptionId.ForumLikeCountDesc,
];

const eventsSortOptions = [
  SortOptionId.Default,
  SortOptionId.EventsStartDateDesc,
];

const blogSortOptions = [
  SortOptionId.Default,
  SortOptionId.BlogPublishDateDesc,
];

const defaultSortOptions = [SortOptionId.Default];

function buildSortDropdownOptions(config) {
  return config.map(id => {
    return { id, valueKey: sortOptionConfig[id].translation };
  });
}

function getSortOptions(
  documentType?: SearchDocumentType,
): NotTranslatedDropdownOption[] {
  if (documentType === SearchDocumentType.Forums) {
    return buildSortDropdownOptions(forumSortOptions);
  }

  if (documentType === SearchDocumentType.Events) {
    return buildSortDropdownOptions(eventsSortOptions);
  }

  if (documentType === SearchDocumentType.Blogs) {
    return buildSortDropdownOptions(blogSortOptions);
  }

  return buildSortDropdownOptions(defaultSortOptions);
}

function getSortRequest(sortOptionId: SortOptionId): ApiTypes.ISearchOrdering {
  return { ordering: sortOptionConfig[sortOptionId].sorters || [] };
}

function getDefaultOrdering(): ApiTypes.ISearchOrdering {
  return { ordering: [] };
}

function getDefaultSortOption() {
  return SortOptionId.Default;
}

function isOrderingSupported(documentType?: SearchDocumentType) {
  if (!documentType) {
    return false;
  }
  return [
    SearchDocumentType.Events,
    SearchDocumentType.Forums,
    SearchDocumentType.Blogs,
  ].includes(documentType);
}

export {
  getDefaultOrdering,
  getDefaultSortOption,
  getSortOptions,
  getSortRequest,
  isOrderingSupported,
};
